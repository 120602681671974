import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import chroma from "chroma-js";
import React, { useState } from "react";
import { Image, Linking, Pressable, ScrollView, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import Button from "../../components/common/Button";
import Footer from "../../components/common/Footer";
import StyledText from "../../components/common/StyledText";

const VirtualReevaluation = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 1000px)" });
  const navigation = useNavigation();

  const scale = chroma.scale(["#e4002b", "#8e051d"]).colors(5);

  const uefa = [
    {
      title: "UEFA C",
      url: "https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/W70goqplocPE1vIgEQKN",
      image:
        "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/OF4VYpRfgUJEH5rG2syJ/admin/images/Futbol%20%2829%29.jpeg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=LcdfVU2aGTySYDPGFFPyIcBI8PFSxNgHqe8iHC2bua4cM5A9KChCmoOrIcAPb%2FEo3RhtYyqeOjFuhbtUfJ0KnEfJz9x0Ke4KA3zDzLsfvx9%2B8PfdvT5cwFFIEOeS7Qkz7KZe80ty%2B9PtYzRMdlNHnEDlE5J7XjaIGiOa1FRB%2BCeeeOd1c4mpfxxniPq29pW1bpvFrrNtP2D0GiSrJb3w6%2BOUPd6L8NVXsYW7mPVuX8bKqmQMu2FQ6dCpNXD0xCAY4TdvSql%2FGjyzVn%2B%2B5FvxkpfK%2BtPaMRMCDvtbvfeBpuIu3%2FBdi7iENsVHbh7UPgOu9%2Fc1Ih12HTtVRkQcIpTL%2Bw%3D%3D",
    },
    {
      title: "UEFA B",
      url: "https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/SVEiaAXL4bfFR35qMfaR",
      image:
        "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/OF4VYpRfgUJEH5rG2syJ/admin/images/Futbol%20%2868%29.jpeg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=WeoMyeUwSMjAIJOpLHk51t8sAQ8FYXLlA0oX%2FrqGvS2Q2ifYAJqSAPkFhZwb2fxG%2Bp0czsEACtXCCJ4GyOC1dhaRQzMQIpwC%2FNLZ6%2BFHPxWPcCx%2F6r3phUwfVvE%2FjttVwucpQw7lSK%2B9X%2BTc8iQfujgrzTvlGlzdZIixNm%2BPqEdxCwz1DRn4LWs9YvZCz77rfMtm0U0h1RNhIBEPnBUyIdlpNhxPF7Ep34q2TOH38uW2rXZ88MWbUPBXwc7qmWNZW4wz2F1kMWXMwa518OzKnaOEjzG1P%2BLmDprKMb3aLiHJKUIxCjLMncaw3l9kO3EdOkGFeggz76XrXwCSQTzLaA%3D%3D",
    },
    {
      title: "UEFA A",
      url: "https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/kF5F8gAFVwvzlKWlkXM5",
      image:
        "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/OF4VYpRfgUJEH5rG2syJ/admin/images/Futbol%20%2879%29.jpeg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=osftVc9BSEGwoulAPzjonPAfTvJEAOvdB5m1F4OgX%2BkffKxfuOHL64VepUl%2FaKe5J2KH4bKJaSxz%2F%2B5yh7xYmm7eOtVU5qVGGJ6ysmbhqk2oFzy2DmLUK14Z3zpQVjo0kDUguVvdHOD2t4rhEdutYNsqvW%2BUZtbG18hZKmp9x2NdhBAyPDmVJb6OBqTDT7Wfc3ir2UJiSOMssCtV34KhlG1KqgZW%2BtxcwkUrFpEmOhinA1Lm6rfAP7rOqw5lgkj4ieagayzagUnL%2BHLQngeUYqmkNBlYcD9CMajSO3oa%2BlFXZIYmD5PvNDguIrLg6lfy6JJhn%2FGESHsZTd%2Fwpky4tw%3D%3D",
    },
    {
      title: "UEFA PRO",
      url: "https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/0xmCHrLjAHAiQ8vWbUmP",
      image:
        "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/OF4VYpRfgUJEH5rG2syJ/admin/images/Futbol%20%281%29.jpeg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=GOgyJmzI7oEzLBoihvBIX%2BJNVZkq2DCnDbxHGfy8puPpx0xSgUHh3x26BWy1vs2eDGr1kK9r1R%2B5ec2SGmwmmrLlcmpH6WBrtQtI1P1pgvmi%2F6KgrgzjGX6OQBm0P1qXGJMnj81iHy8pS%2FVFNNEiXG6uR3n1tx7ub7Kr8leL9IWdi%2F%2F82jrH0X3sCUTBEpw2cJH38UC59dXMvwTTDXmsmkQVM39KJaNgYAlsat%2BgIKVGKSOf57IjsPEE3mtu2MFQS0GXAYYI1gqUXz2BCHU1N5xCBIRsX8322bu286nHgOIxyCAgY38%2F17nwOgeWG9WbGT6GHl7v7aXz0e%2FFoE39ag%3D%3D",
    },
  ];

  const federativos = [
    {
      title: (
        <span>
          Nacional C<br />
          Monitor Nacional
          <br />
          Monitor Territorial de Fútbol
        </span>
      ),
      url: "https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/cW3gifDkoqMwbVxr7PjN",
      image:
        "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/OF4VYpRfgUJEH5rG2syJ/admin/images/Futbol%20%2848%29.jpeg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=boAsmtkHvEhO1g489fu225BX3xrCyCcAJd8MvreMpIDn8a%2Fpp5w8YdU5IOtCZEaNu31a79PHebWeWFPL%2BYRJXL%2Bb51DR5V4muPPHgU6tk0rX0iTFUYvwFmGHTfd76%2B%2FCROfh8GLf6UEI%2B1CrpesC99Z%2BjdCFBzdZhHEApm%2BxxS9ID9qXdfI80JmPgcjwMiXde68MuzhK1ZXqI3nugve8HQRDXgFV2G2RqffXUKr0bL2w2jl%2F8NmbENc7nA5t%2FvnAjTRgZ2dI8yZdkFsKDTD7%2FmkzzWGZMVZ6vWGjjnWW331XcDxl73c5cSi4p8HAyOSICO%2FgTFSjmhJvacbgMKu0pw%3D%3D",
    },
    {
      title: (
        <span>
          Básico
          <br />
          Nivel 1<br />
          Juvenil Instructor de Fútbol Base
        </span>
      ),
      url: "https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/Sd57scjnkTX3OyZhgeCW",
      image:
        "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/OF4VYpRfgUJEH5rG2syJ/admin/images/Futbol-Porteras%20MA%20%285%29.JPG?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=SuLSZRZJQoYXIG1AB7X1wpkAjGn0mdwd2jGEpDwICOTeGCC%2FXBYmoOm7PDQy1sReQdZM8Wj7VZU4vXzT2qkrj%2FzkieIdnHWXOQzp4GGt2fYUwE66oWG7Yhv5WvorWedX2%2FF4Dgtu1czP49HeYVz3S2Lu3Lghc5n9U0iFY%2BS71oLrCCMHapnUi4%2F4DnZf6llRF7K6AUZYBfHBqlCziAtv0ruGbXTeb%2FxKAhzSuJWh2kuMdQH2QEFYn3Ok74PBqHiFhYh7W1XXRZnkB7UfLNGeiVm%2FM6TDlSnGkHQNg79Tv4D%2FqfAi7BS0nrLzWgSajl7SoezkDryUhabtX3EumFAaeQ%3D%3D",
    },
    {
      title: (
        <span>
          Avanzado
          <br />
          Nivel 2<br />
          Regional de Fútbol
        </span>
      ),
      url: "https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/7Z9ws5mOSHIi98JM65ol",
      image:
        "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/OF4VYpRfgUJEH5rG2syJ/admin/images/Futbol-Porteros%20%289%29.jpeg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=d4ozd7sCpJXd8Vogd1No9Qgw7mVjYYRVtgVK8qqxmIWZdIZrPcC7ET%2FTrO8CzZlaWuQYjSg81RF4sx6p1XI61TAAWa0DaKXA5ONurzMjMGP%2B1fecTbVz4DuB9qI1JgDX%2BuJtI%2BcoDP09CRZptbGJFqNj1jvXsgHQaRJ%2FJuceCEP4ZxvwykXn9rgyM0F6ul0dwK06aSJnxB0NUpYqNY80m4%2FQgdGXnPhZD79oqxQA1gZc5kU12viFLIgOThK2ClPkJh8LSF1Tl%2BTE8N1BDOc3ElPHf1lY6Uwz6FfRT1A6EEx98DHZiOVqER3I2Ci830sY5aK9FuZHlZUDlxVfgE6pWg%3D%3D",
    },
    {
      title: (
        <span>
          Profesional
          <br />
          Nivel 3<br />
          Nacional de Fútbol
        </span>
      ),
      url: "https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/rYXLlo5iqeG5WusWNjOt",
      image:
        "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/OF4VYpRfgUJEH5rG2syJ/admin/images/Futbol%20%2810%29.jpg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=VdqFGdoLZF53k3m3LcYyEyTfVSP1W5BIq4d79GSCTZPSBVmOexy%2FiMENqurVA5c%2FjtflUlSbGnYBUZTiGQYRRl%2BlIbmDa1dyFOUlZ%2BBMnq1uN2fD%2BHxZuIgonfw6FvLVq39YwRNFuOFywKpeAvUtnBkxXQJ5iI9wmu3DfmGWLR6EYXTXs51jKAV311jz07wpjR5qH8InVc97o6sI5Wgl3rUtFyKienMOmLnEqQxoIYCqo5lkmzkf7moM1xjSMRtkMsXHpoXsfcy43B5Trxhrb1JRPAJGbM%2FhUK4ZfZPkaO%2F%2BzN6m7iU2HCkZ%2FyFRc0ZPrnXo%2FsKGxhSRfucoTYYDLg%3D%3D",
    },
  ];

  const tecnicos = [
    {
      title: "Técnico Deportivo Ciclo Inicial",
      url: "https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/gBYYbOlCGLdFCg9rc7lJ",
      image:
        "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/OF4VYpRfgUJEH5rG2syJ/admin/images/Instalaciones%20RFEF%20%2848%29.JPEG?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=Eg8lJBWC4IbCP79RnpxUqZE3vsx4sG9zYoaIIRltvEx0rFhARRkT5bapEnfzfDJjRS9%2BSR%2BocvenCkOIwJTdvxmE1zuf2BP1rTw%2BuQ5P6WWjNAuM8TFk3JRykPcDS%2B9BbmD%2B5aQJ0%2FXohn6F5n23Isb4PjbNFjr%2BlJclDZcxiwvB5fdCtAL9tQDNvVQFcuK9AnlZ%2BywkZQHbtqssAHUBUvCt%2FWbXOJdwXnqIKnVEi6OymNpj2S3s2wXBDJQgmAYAvrMQ1QfwcVC8ZgappV4yo8kmqfU6a0zK6SvX0LOaZ69YKL1E8%2BHm6I4k1liMzNxDbP3tWA%2BaZTAZfjAtHlQQPA%3D%3D",
    },
    {
      title: "Técnico Deportivo",
      url: "https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/KIAsHtCcvi1Qjqbz8Hoc",
      image:
        "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/admin/OF4VYpRfgUJEH5rG2syJ/images/1733905558946-gbifdyrxeae6dmijpeg.jpeg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=Wom27CQMH2Ezb8a7tgIk%2BS2LMhGtlXtOlnyG4YddljkSW6ZxGktVZelY7XyIy8w1EndVRqTK%2BzaLdl7vxZg%2BH6%2BfwEDrCvmcgHfZBhuwzIsSF3Hwz7I0J38t5ComDC7jGMSAuWjDRuvLBMyQbVH1uD4xJfSAex2REFEVcf6NPCPmPiHFRxOmUFKqLrACL2CLQ2cTndevQfBDCNuRb3eSU8gZ2OZIPbIpPTW7V3IykxJKy7IBgdY%2Fc6b9s2mCAUKqLpfKd5gOEoeyHxbll36%2F6dOYQMizCuy8NpVt3IYwxhp78lQfAenDxxswZnThzVbe82xCjZ65vNHYOuMog%2Fo7XA%3D%3D",
    },
    {
      title: "Técnico Deportivo Superior",
      url: "https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/Wz9qpvmIKEPHofJU75D4",
      image:
        "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/admin/OF4VYpRfgUJEH5rG2syJ/images/1733916467536-gbfi57uauaa-wntjpg.jpeg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=KdjwFwgBY1QgQbw10cfuzjgEKbLZUbw4Ctw5IAjNEZQ8OpnI%2BGDoH%2BYD6dL7%2BGuMWUYQCRV1p1akEw52R2%2BOoCpLS02XDSkQJeJSlbslp9OHsMjHX8319Fc25vs7x5bV48RXXdCMnGE0nfVrBIh8OqnZ5N6Lh1RqJRknb8ZeSQMzQCg7Y5xGi1wVfGxlCdYVGjuRVcSlF674kTbZpYhwuRxLnOtk%2Fu8HWnS9ShhQEcmsB0ZyxSEgdTAjth9VK%2BBlb%2BrQZQoOgJGsHWCYoLBt4H7rWTqBr5muUdoK2oxn8E75a%2BkZcNjlqLB8wlx2RHbDN7iP8s%2BrtTqPTJ73WR4m6A%3D%3D",
    },
  ];


  const sala = [
    {
      title: "UEFA FUTSAL B",
      url: "https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/jbGXJIK8K6vMQ3yZyvDM",
      image:
        "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/OF4VYpRfgUJEH5rG2syJ/admin/images/Futbol-Sala%20%2827%29.jpeg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=DtaplHeJlAgZEETgYlvx6eJzjpZ0ZzaCESrcd1kX%2BAxFGNtMnduR7%2FNTWB%2BP%2BW%2FVjjgs6gXmR%2B9PD%2BnTcX5J4sKtZQVIyLiucNG5lzTgSvN85rGkxQgoizvBICheB58N%2BP4OvlcMC9tJ2jKboBbMI0tkDh9PMoYwvVmUq4N%2FybQvWMm9f%2B9PvNTq%2F6etMKHhS0DyLjdYV6ug7VQP5YihKUnfqx5sly8vvOTa38kIOaXEE6dLKxuD7BSBm2IiIKpS23EAos3fBILNt3f0VrawycVuNq5Q%2FPCcF7Xvdp2RJWJEvQC4Y7H%2BOkeLh3cigx5MhHyZcvy0etfTgHQHtLc4jA%3D%3D",
    },
    {
      title: "Titulación Federativa de Fútbol Sala ",
      url: "https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/9BzwOZZOFLfz4zhQfRPI",
      extra: `Monitor de fútbol sala, Nacional C de fútbol sala, Nivel 1 de fútbol sala, Básico de fútbol sala, Nacional B de fútbol sala, Nivel 2 de fútbol sala, Avanzado de fútbol sala, Nacional A de fútbol sala, Nivel 3 de fútbol sala, Profesional de fútbol sala, Nacional Profesional de fútbol sala`,
      image:
        "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/admin/OF4VYpRfgUJEH5rG2syJ/images/1682340642640-futbol-sala.jpeg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=FfMaG7TOeyFYdKrnQxEhE5FNQ0Qo%2FyiOi6Grt%2BM%2BdKAGC39Sj1nhS4VYxmautzKaP0EbVTs6oThTJDi8tAp0D3CHlXUUDdf0TEv1b5Ca4mjBPfkxJ0nZ%2FRtFO%2FgHE%2BlezWAuWXoViAFF8bLK%2BP2w2qsB1oi7THhEAo1wY8EoLi9gbyj3aSbaHrjyH%2F3u0qzDpVUQ8Y2iFn2c98O5izeO7aiB2UN0QDGsfyg8Qw%2BQYhzp7u4Nc9wO1s5ZRvPZTX%2FfVB2VsuP7vcBlAA%2BBV977nuCDUULepkkslBwOp4KmgR%2FI4SoZoxwm8U5ejoDhXq3ZHLsGOBvFwsng2gpw37HJ6Q%3D%3D",
    },
    {
      title: "Titulación de Técnico Deportivo en Fútbol Sala",
      url: "https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/JmmyJBlf2KAtGLlN6w2G",
      extra: `Técnico Deportivo Ciclo Inicial en fútbol sala, Técnico Deportivo en fútbol sala, Técnico Deportivo Ciclo Superior en fútbol sala`,
      image:
        "https://storage.googleapis.com/rfef-escuela-entrenadores.appspot.com/OF4VYpRfgUJEH5rG2syJ/admin/images/Futbol-Sala%20%288%29.jpg?GoogleAccessId=firebase-adminsdk-s5asd%40rfef-escuela-entrenadores.iam.gserviceaccount.com&Expires=2051222400&Signature=npujHBcX2PGvOxQnzEeVCpq1aiVUhOBBS7pxSms4%2BvlhPSOVcGFyZ7tsd4GmVtlNdzA9nD%2BD%2F9mXirsGf5aN5ayy%2BG2%2F6nOEd5slxUIs%2FvZ6frw%2BcdjeEz%2BOo57NjD2Q7NdEyLS6gYjDP83IcUBvW8VFgbXsGVOjqpHs8vS7uOLTxA7kmBcxzCzvkGt6xVETg0RXUY%2B3Ng4A%2BjxHgX%2FL9pKJxHb2SYu89QTncVPkjemNp7SGDUTHyrUArqImZzXjdkkrvl3rITU0fHqVCqdXFealZyk2VLeYbVXxp9EK%2BKhV2pPALxFwA1cuM97LtJEL5E5mvCtsJmEAFEKGRwcyYg%3D%3D",
    },
  ];

  return (
    <>
      <ScrollView
        style={{ flex: 1, backgroundColor: "white" }}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            paddingTop: 0,
            alignSelf: "center",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              backgroundColor: "#06162D",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 50,
              paddingHorizontal: 30,
              paddingBottom: 80,
            }}
          >
            <View
              style={{
                width: "100%",
                maxWidth: 1200,
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
                alignSelf: "center",
              }}
            >
              <StyledText
                style={{
                  fontFamily: "Title",
                  fontSize: 60,
                  color: "white",
                  textAlign: "center",
                }}
              >
                Revaluación de su Licencias UEFA
              </StyledText>
              <StyledText
                style={{ fontSize: 22, color: "white", textAlign: "center" }}
              >
                Desde la Escuela de Entrenadores de la Real Federación Española
                de Fútbol, hemos habilitado diferentes cursos de revaluación
                virtual para que podáis renovar la licencia UEFA u obtener el
                Certificado correspondientes de haber superado las 15h, para
                tramitar licencia a partir del 1 de juio de 2025.
              </StyledText>
            </View>
          </View>
        </View>

        <View
          style={{
            maxWidth: 1200,
            width: "100%",
            zIndex: 3,
            alignSelf: "center",
            gap: 20,
            paddingVertical: 40,
            paddingHorizontal: 10,
          }}
        >
          <StyledText style={{ fontSize: 22, textAlign: "center" }}>
            Llevaremos un control de asistencia y visualización, donde te
            aparecerán varios avisos de presencialidad hasta el final de los
            vídeos, a los que deberemos responder para superar la video tarea.
          </StyledText>

          <StyledText style={{ fontSize: 22, textAlign: "center" }}>
            Para preinscribirte a la revaluación virtual, deberás buscar y
            clicar sobre el curso que corresponde a tu titulación, completar tus
            datos personales y adjuntar una foto de tu licencia. En 24/48h
            recibirás un email confirmando la matrícula al curso, por favor,
            revisa la carpeta spam o correo no deseado.
          </StyledText>

          <StyledText style={{ fontSize: 22, textAlign: "center" }}>
            Una vez realizadas las 15h, verás en tu área personal, en el
            apartado de LICENCIAS, un nuevo botón que pone "RENOVAR LICENCIA" en
            caso de obtener el certificado tendrás que clicar sobre CERTIFICADOS
            DE RECICLAJE y generar el certificado. Clicando sobre él,
            accederemos al pago de los 25€ y se generará automáticamente la
            licencia ya renovada o el certificado con una validez de 3 años.
          </StyledText>
          <StyledText
            style={{
              fontSize: 24,
              marginTop: 20,
              fontWeight: "bold",
              color: "black",
            }}
          >
            Elija su Licencia UEFA de fútbol
          </StyledText>
          <View
            style={{
              flexDirection: isPhone ? "column" : "row",
              gap: 10,
              width: "100%",
              alignSelf: "center",
              marginBottom: 50,
            }}
          >
            {uefa.map((item, index) => (
              <CourseCard
                key={index}
                height={300}
                title={item.title}
                url={item.url}
                image={item.image}
                isPhone={isPhone}
              />
            ))}
          </View>

          <StyledText
            style={{
              fontSize: 24,
              marginTop: 20,
              fontWeight: "bold",
              color: "black",
            }}
          >
            Elija su Titulación Federativa de fútbol
          </StyledText>
          <View
            style={{
              flexDirection: isPhone ? "column" : "row",
              gap: 10,
              width: "100%",
              alignSelf: "center",
              marginBottom: 50,
            }}
          >
            {federativos.map((item, index) => (
              <CourseCard
                key={index}
                border={item.border}
                title={item.title}
                url={item.url}
                image={item.image}
                isPhone={isPhone}
              />
            ))}
          </View>

          <StyledText
            style={{
              fontSize: 24,
              marginTop: 20,
              fontWeight: "bold",
              color: "black",
            }}
          >
            Elija su formación de Técnico Deportivo en fútbol
          </StyledText>
          <View
            style={{
              flexDirection: isPhone ? "column" : "row",
              gap: 10,
              width: "100%",
              alignSelf: "center",
              marginBottom: 50,
            }}
          >
            {tecnicos.map((item, index) => (
              <CourseCard
                key={index}
                border={item.border}
                title={item.title}
                url={item.url}
                image={item.image}
                height={360}
                isPhone={isPhone}
              />
            ))}
          </View>

          <StyledText
            style={{
              fontSize: 24,
              marginTop: 20,
              fontWeight: "bold",
              color: "black",
            }}
          >
            Elige tu formación de fútbol sala según tu máxima titulación
          </StyledText>
          <View
            style={{
              flexDirection: isPhone ? "column" : "row",
              gap: 10,
              width: "100%",
              alignSelf: "center",
              marginBottom: 50,
            }}
          >
            {sala.map((item, index) => (
              <CourseCard
                key={index}
                border={item.border}
                title={item.title}
                url={item.url}
                image={item.image}
                height={450}
                extra={item.extra}
                isPhone={isPhone}
              />
            ))}
          </View>
          {/* <View >
						<StyledText style={{ fontSize: 24, marginBottom: 20, fontWeight: "bold", fontFamily: "TitleWide", color: "black" }}>Proceso de Renovación</StyledText>

						<StyledText style={{ fontSize: 20 }}>1 - Elija su opción preferida: presencial o virtual.</StyledText>
						<StyledText style={{ fontSize: 20 }}>2 - Complete las 15 horas requeridas de formación.</StyledText>
						<StyledText style={{ fontSize: 20 }}>3 - Acceda a su área personal en la plataforma de la RFEF.</StyledText>
						<StyledText style={{ fontSize: 20 }}>4 - Busque el botón "RENOVAR LICENCIA" en el apartado de licencias.</StyledText>
						<StyledText style={{ fontSize: 20 }}>5 - Realice el pago de 25€ para la renovación.</StyledText>
						<StyledText style={{ fontSize: 20 }}>6 - Su licencia se renovará automáticamente hasta el 31.12.2027.</StyledText>

					</View> */}
          {/* <CollapsableTwo /> */}
          {/* <CollapsableOne /> */}
        </View>

        <Footer limited={false} />
      </ScrollView>
    </>
  );
};

export default VirtualReevaluation;

const IconRow = ({ icon, text }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        paddingTop: 0,
        alignSelf: "center",
        gap: 10,
      }}
    >
      <Ionicons name={icon} size={24} />
      <StyledText style={{ fontSize: 18, fontWeight: "bold", color: "black" }}>
        {text}
      </StyledText>
    </View>
  );
};

const CollapsableOne = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <View
      style={{
        backgroundColor: "white",
        padding: 20,
        borderRadius: 10,
        shadowColor: "rgba(0,0,0,.2)",
        shadowOffset: { width: 0, height: 5 },
        shadowRadius: 10,
        elevation: 5,
      }}
    >
      <Pressable
        onPress={() => setIsOpen(!isOpen)}
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          gap: 10,
        }}
      >
        <StyledText
          style={{
            marginBottom: 0,
            fontSize: 24,
            fontWeight: "bold",
            fontFamily: "TitleWide",
            color: "black",
          }}
        >
          Instrucciones Revaluacion Virtual
        </StyledText>
        <Ionicons name="add" size={24} color="black" />
      </Pressable>

      <View
        style={{ gap: 10, height: isOpen ? undefined : 0, overflow: "hidden" }}
      >
        <StyledText style={{ marginTop: 20, fontSize: 20 }}>
          Te damos la bienvenida a la Revaluación Virtual de la licencia UEFA, A
          partir del día 11 a las 9 am hora española, se activará el curso
          dentro de la plataforma https://onformacion.rfef.es/ accede a la
          plataforma desde el botón de ACCESO situado en la parte superior a la
          derecha, si no recuerdas la contraseña puedes recuperarla desde esa
          pantalla y dentro de MIS PROGRAMAS verás el curso.
        </StyledText>
        <Image
          style={{
            width: "100%",
            height: "400px",
            resizeMode: "contain",
            borderRadius: 15,
            marginBottom: 10,
          }}
          source={{
            uri: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2Fimage001.png?alt=media&token=cb0d0f44-534a-4893-8b51-c0f45bfb26c6",
          }}
        />

        <StyledText style={{ fontSize: 20 }}>
          Una vez accedas al curso te aparecerán 2 opciones;
        </StyledText>
        <View style={{ paddingLeft: 20 }}>
          <StyledText style={{ fontSize: 18 }}>
            <StyledText style={{ fontWeight: "bold" }}>
              {" "}
              -Video tareas de 15h de actualización.
            </StyledText>{" "}
            Consiste en visualizar las 15h de video tareas donde deberás entrar
            para hacer la revaluación completa.{" "}
          </StyledText>
          <StyledText style={{ fontSize: 18 }}>
            <StyledText style={{ fontWeight: "bold" }}>
              {" "}
              -Certificados de otras federaciones nacionales,
            </StyledText>{" "}
            donde deberás subir los certificados que hayas realizado en otros
            países del convenio UEFA, si los subes en este apartado se
            descontarán de las 15 horas que debes realizar.
          </StyledText>
        </View>
        <Image
          style={{
            width: "100%",
            height: "400px",
            resizeMode: "contain",
            borderRadius: 15,
            marginBottom: 10,
          }}
          source={{
            uri: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2Fimage002.png?alt=media&token=56121bd1-5612-42c1-aa32-96b296cb0b70",
          }}
        />
        <StyledText style={{ fontSize: 20 }}>
          Dentro de las video tareas tendrás a tu disposición los videos, que se
          irán habilitando para ir realizando la visualización y completando las
          horas. En la parte inferior del video te aparecerá el horario de
          visualización del mismo.
        </StyledText>
        <Image
          style={{
            width: "100%",
            height: "400px",
            resizeMode: "contain",
            borderRadius: 15,
            marginBottom: 10,
          }}
          source={{
            uri: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2Fimage003.png?alt=media&token=692208da-e7b1-4b3e-933b-8b4626d46fd0",
          }}
        />

        <StyledText style={{ fontSize: 20 }}>
          Este año para comprobar la asistencia tendrás que clicar en los
          botones que aparecerán en pantalla de forma aleatoria y, muy
          importante, al finalizar el video clicar en COMPLETAR CONTENIDO. Te
          aparecerá una advertencia, informando en cada video.
        </StyledText>
        <View style={{ paddingLeft: 20 }}>
          <StyledText style={{ fontSize: 18 }}>
            {" "}
            - A lo largo del video aparecerán{" "}
            <StyledText style={{ fontWeight: "bold" }}>
              3 mensajes de confirmación
            </StyledText>{" "}
            de visualización.
          </StyledText>
          <StyledText style={{ fontSize: 18 }}>
            {" "}
            - Para superar este ejercicio{" "}
            <StyledText style={{ fontWeight: "bold" }}>
              deberás confirmar al menos 2
            </StyledText>{" "}
            de las 3 veces que aparezca ese mensaje.
          </StyledText>
          <StyledText style={{ fontSize: 18 }}>
            {" "}
            - Tendrás{" "}
            <StyledText style={{ fontWeight: "bold" }}>
              90 segundos
            </StyledText>{" "}
            para confirmar cada mensaje.
          </StyledText>
          <StyledText style={{ fontSize: 18 }}>
            {" "}
            - Puedes{" "}
            <StyledText style={{ fontWeight: "bold" }}>
              repetir tantas veces como quieras
            </StyledText>{" "}
            el ejercicio durante el tiempo disponible en las convocatorias.
          </StyledText>
        </View>
        <Image
          style={{
            width: "100%",
            height: "400px",
            resizeMode: "contain",
            borderRadius: 15,
            marginBottom: 10,
          }}
          source={{
            uri: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2Fimage005.png?alt=media&token=23c1ebed-8ac1-4286-8db5-600c4bc2241a",
          }}
        />
        <StyledText style={{ fontSize: 20 }}>
          Realizadas las 15h te aparecerá en tu apartado de LICENCIAS el botón
          de RENOVACIÓN, clicando sobre él te llevará al TPV de pago donde
          deberás abonar los 25€ de generar la licencia y la tendrás en
          automáticamente renovada en tu área personal, también se mandará a
          imprimir para en un plazo de 3 meses poder pasar a recogerla
          físicamente por las oficinas de la RFEF o enviar un mensajero a por
          ella con una autorización.
        </StyledText>
        <Image
          style={{
            width: "100%",
            height: "400px",
            resizeMode: "contain",
            borderRadius: 15,
            marginBottom: 10,
          }}
          source={{
            uri: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2Fimage007.png?alt=media&token=3f225a9f-9112-482c-950a-84a8e89e4f93",
          }}
        />
        <StyledText style={{ fontSize: 20 }}>
          Esperamos que las ponencias que te mostramos sean de tu interés y
          sirvan para seguir formándote y avanzando como entrenador.
        </StyledText>
        <Button
          round={7}
          height={35}
          style={{ marginTop: 20 }}
          label="Descarga el Programa"
          onPress={() =>
            Linking.openURL(
              "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2FProgramacio%CC%81n%20Revaluacio%CC%81n%20UEFA%20Virtual.pdf?alt=media&token=4c2bfb9f-9b73-46af-9c9f-706fbb7e4159",
            )
          }
        />
      </View>
    </View>
  );
};

const CollapsableTwo = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <View
      style={{
        backgroundColor: "white",
        padding: 20,
        borderRadius: 10,
        shadowColor: "rgba(0,0,0,.2)",
        shadowOffset: { width: 0, height: 5 },
        shadowRadius: 10,
        elevation: 5,
      }}
    >
      <Pressable
        onPress={() => setIsOpen(!isOpen)}
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          gap: 10,
        }}
      >
        <StyledText
          style={{
            marginBottom: 0,
            fontSize: 24,
            fontWeight: "bold",
            fontFamily: "TitleWide",
            color: "black",
          }}
        >
          Instrucciones Revaluacion Presencial
        </StyledText>
        <Ionicons name="add" size={24} color="black" />
      </Pressable>

      <View
        style={{ gap: 10, height: isOpen ? undefined : 0, overflow: "hidden" }}
      >
        <StyledText style={{ marginTop: 20, fontSize: 20 }}>
          Bienvenido a las jornadas presenciales de la Revaluación Licencia UEFA
          PRO
        </StyledText>

        <StyledText style={{ fontSize: 20 }}>
          <StyledText style={{ fontWeight: "bold" }}>Lugar: </StyledText>jornada
          presencial se realizará en la sede de la RFEF en las Rozas de Madrid,
        </StyledText>
        <StyledText style={{ fontSize: 20 }}>
          <StyledText style={{ fontWeight: "bold" }}>Horario: </StyledText>el
          viernes 11 desde las 15h hasta las 20:30h
        </StyledText>
        <StyledText style={{ fontSize: 20 }}>
          <StyledText style={{ fontWeight: "bold" }}>Acceso: </StyledText>Podrás
          aparcar en el parking exterior y en los alrededores.
        </StyledText>
        <StyledText style={{ fontSize: 20 }}>
          <StyledText style={{ fontWeight: "bold" }}>Pautas:</StyledText>
        </StyledText>

        <View style={{ paddingLeft: 20 }}>
          <StyledText style={{ fontSize: 18 }}>
            {" "}
            - Recomendamos asistir con 30 minutos de antelación.
          </StyledText>
          <StyledText style={{ fontSize: 18 }}>
            {" "}
            - Deberás acreditarte en los mostradores de la entrada organizados
            por apellidos, donde te entregaremos un obsequio de bienvenida.
          </StyledText>
          <StyledText style={{ fontSize: 18 }}>
            {" "}
            - Al acceder tendrás que escanear el código QR desde el móvil, que
            te mostraremos en unos tótems en la entrada junto a cada mostrador,
            para ello tienes que entrar en https://onformacion.rfef.es/ acceder
            a la plataforma y clicar sobre ESCANER. En caso de no poder tendrás
            un área de incidencias.
          </StyledText>
          <StyledText style={{ fontSize: 18 }}>
            {" "}
            - A partir de las 14h estará habilitado un café de bienvenida
          </StyledText>
          <StyledText style={{ fontSize: 18 }}>
            {" "}
            - Al finalizar la jornada no olvides escanear el código QR de salida
            para que te computen las horas realizadas.
          </StyledText>
          <StyledText style={{ fontSize: 18 }}>
            {" "}
            - Esperamos que disfrutes de la jornada.
          </StyledText>
        </View>

        <StyledText style={{ fontSize: 20 }}>
          Realizadas las 15h te aparecerá en tu apartado de LICENCIAS el botón
          de RENOVACIÓN, clicando sobre él te llevará al TPV de pago donde
          deberás abonar los 25€ de generar la licencia y la tendrás en
          automáticamente renovada en tu área personal, también se mandará a
          imprimir para en un plazo de 3 meses poder pasar a recogerla
          físicamente por las oficinas de la RFEF o enviar un mensajero a por
          ella con una autorización.
        </StyledText>
        <Image
          style={{
            width: "100%",
            height: "400px",
            resizeMode: "contain",
            borderRadius: 15,
            marginBottom: 10,
          }}
          source={{
            uri: "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2Fimage007.png?alt=media&token=3f225a9f-9112-482c-950a-84a8e89e4f93",
          }}
        />
        <StyledText style={{ fontSize: 20 }}>
          Esperamos que las ponencias que te mostramos sean de tu interés y
          sirvan para seguir formándote y avanzando como entrenador.
        </StyledText>
        <Button
          round={7}
          height={35}
          style={{ marginTop: 20 }}
          label="Descarga el Programa"
          onPress={() =>
            Linking.openURL(
              "https://firebasestorage.googleapis.com/v0/b/rfef-escuela-entrenadores.appspot.com/o/assets%2Frevaluacionlanding%2FPrograma%20Revaluacion%20Licencia%20UEFA%20Presencial.pdf?alt=media&token=e0350b86-c550-4ed6-aee9-08ca6072e4f4",
            )
          }
        />
      </View>
    </View>
  );
};

const CourseCard = ({
  border = "#E4002B",
  title,
  url,
  image,
  extra,
  height = 350,
  isPhone = false,
}) => {
  return (
    <View
      style={{
        flex: isPhone? undefined: 1,
        backgroundColor: "white",
        padding: 20,
        borderRadius: 7,
        borderWidth: 2,
        borderColor: border,
        height: isPhone? undefined: height,
      }}
    >
      <View
        style={{
          flex: 1,
          paddingTop: 0,
          gap: 10,
          justifyContent: "space-between",
        }}
      >
        <Image
          style={{
            width: "100%",
            aspectRatio: 1.77,
            resizeMode: "cover",
            borderRadius: 15,
            overflow: "hidden",
            marginBottom: 10,
            maxHeight: 180,
          }}
          source={{
            uri: image,
          }}
        />
        <View
          style={{
            flexDirection: "column",
            // alignItems: "center",
            width: "100%",
            paddingTop: 0,
            flex:1,
            // alignSelf: "center",
            gap: 10,
          }}
        >
          <StyledText
            style={{ fontSize: 16, fontFamily: "TitleWide", color: "black" }}
          >
            {title}
          </StyledText>
          {extra && <StyledText style={{ fontSize: 14, color: "black" }}>{extra}</StyledText>}
        </View>
        <Button
          round={7}
          label="Preinscribirse"
          onPress={() => Linking.openURL(url)}
        />
      </View>
    </View>
  );
};
